import React, { useEffect, useRef, useState } from "react";
import teamMember from "./../assets/image/teammember-profileimg.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./TeamMemberGroup.css";
import courseLeft from "../../components/assets/icon/course-left-right (1).svg";
import courseRight from "../../components/assets/icon/course-left-right (2).svg";
import TeamMemberCard from "components/TeamMemberCard/TeamMemberCard";
import Slider from "react-slick";
const TeamMemberGroup = ({ teamname, data }) => {
  AOS.init({ duration: 2000 });
  const [slidersetting, setslidersetting] = useState();

  const slider1 = useRef(null);
  var setting = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: "50px",
  };
  var setting1 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  var setting2 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
  };
  var setting3 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const updatesetting = () => {
      let screenwidth = window.innerWidth;
      if (screenwidth <= 568) {
        setslidersetting(setting3);
      } else if (screenwidth <= 767) {
        setslidersetting(setting2);
      } else if (screenwidth <= 925) {
        setslidersetting(setting2);
      } else if (screenwidth <= 1280) {
        setslidersetting(setting1);
      } else {
        setslidersetting(setting);
      }
    };
    window.addEventListener("resize", updatesetting);
    updatesetting();
  }, []);
  return (
    <div className="padding-web mt-5 row">
      <h1 className="text-center teamgroup-teamname">{teamname}</h1>
      {/* <Swiper
        spaceBetween={20}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
        slidesPerView={"auto"}
      >
        {numbers.map((data, index) => (
          <SwiperSlide>
            <TeamMemberCard
              // data-aos="zoom-in"
              profileimg={teamMember}
              name="Ashish Sardesai"
              position="CEO"
              designation="Founder"
              bio="VIOSA is the SkillTech industry's first unified technology platform that coaches students in acquiring
          sector-based competencies."
            />
          </SwiperSlide>
        ))}
      </Swiper> */}
      {/* <div className=" row team-card-container">
        <TeamMemberCard
          data-aos="zoom-in"
          profileimg={teamMember}
          name="Ashish Sardesai"
          position="CEO"
          designation="Founder"
          bio="VIOSA is the SkillTech industry's first unified technology platform that coaches students in acquiring
          sector-based competencies."
        />
        <TeamMemberCard
          data-aos="zoom-in"
          profileimg={teamMember}
          name="Ashish Sardesai"
          position="CEO"
          designation="Founder"
          bio="VIOSA is the SkillTech industry's first unified technology platform that coaches students in acquiring
          sector-based competencies."
        />
        <TeamMemberCard
          data-aos="zoom-in"
          profileimg={teamMember}
          name="Ashish Sardesai"
          position="CEO"
          designation="Founder"
          bio="VIOSA is the SkillTech industry's first unified technology platform that coaches students in acquiring
          sector-based competencies."
        />
        <TeamMemberCard
          data-aos="zoom-in"
          profileimg={teamMember}
          name="Ashish Sardesai"
          position="CEO"
          designation="Founder"
          bio="VIOSA is the SkillTech industry's first unified technology platform that coaches students in acquiring
          sector-based competencies."
        />
      </div> */}
      <div className="mt-4">
        <div
          className="grid-cols-1 lg:grid-cols-1 md:grid-cols-1 course-view-slider"
          style={{ position: "relative" }}
        >
          <div className="flex arrow-mobile items-center justify-end mb-2 gap-x-6">
            <img
              className="course-left-right"
              onClick={() => slider1.current.slickPrev()}
              src={courseLeft}
              alt=""
            />
            <img
              onClick={() => slider1.current.slickNext()}
              className="course-left-right"
              src={courseRight}
              alt=""
            />
          </div>
          {/* <ArrowButton slider={slider1} />
                <ArrowNext slider={slider1} /> */}

          <Slider ref={slider1} {...slidersetting}>
            {data.map((data, index) => (
              <TeamMemberCard
                key={index}
                // data-aos="zoom-in"

                teamMember={teamname}
                profileimg={data?.image}
                name={data?.name}
                position={data?.position}
                linkedin={data?.linkedin}
                bio={data?.bio}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default TeamMemberGroup;
